header {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 1;
}

header h1 {
  font-size: 1.8rem;
  margin: 0.5rem;
  text-shadow: 1px 1px 3px #ffffff, 1px 1px 1px #ffffff;
}

header h2 {
  font-size: 1.4rem;
  margin: 0 0 0 0.5rem;
  text-shadow: 1px 1px 3px #ffffff, 1px 1px 1px #ffffff;
}

.error-message {
  display: flex;
  align-items: center;
  background: rgb(222, 75, 75);
  background: linear-gradient(135deg, rgba(222, 75, 75, 1) 18%, rgba(255, 114, 114, 1) 100%);
  color: white;
  font-size: 1rem;
  height: 45px;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  width: 100%;
}
