* {
  box-sizing: border-box;
}

body {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 12px;
}

h1 {
  margin: 0.5rem 0;
}

h3 {
  margin: 0;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
