.mapboxgl-popup-content {
  padding: 10px;
  min-width: 150px;
}

.mapboxgl-popup-content h3 {
  margin: 0 0 6px 0;
  padding-bottom: 6px;
  border-bottom: 1px solid #efefef;
}

.exception-note {
  color: grey;
  font-size: 8pt;
  line-height: 10pt;
}

.animation-control-icon {
  height: 12px;
  width: 12px;
}
