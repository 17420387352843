.legend {
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  bottom: 35px;
  position: absolute;
  left: 10px;
  z-index: 1;
}

.legend-section {
  display: flex;
  margin: 0;
  padding: 10px;
}

.legend-section:not(:last-child) {
  border-bottom: 1px solid #efefef;
}

.legend-section--column {
  flex-direction: column;
}

.legend label {
  font-weight: 600;
  margin-right: 8px;
}

.legend-section__date {
  display: flex;
  width: 100%;
}

.legend select {
  flex: 1;
  height: 20px;
  margin-left: 8px;
}

.legend-scale {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  grid-auto-flow: column;
  padding: 0 0 5px 0;
}

.legend-scale__step {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.legend-scale__step-swatch {
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

.source-notes {
  color: #585858;
  font-size: 8pt;
  line-height: 10pt;
  max-width: 200px;
}

.source-notes a,
a:visited,
a:hover,
a:active {
  color: #585858;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 3px;
  border: 1px solid #c0c0c0;
  background-color: white;
}

button:hover {
  cursor: pointer;
}

button:disabled {
  background-color: #f8f8f8;
}

button:disabled .animate-icon {
  fill: #c0c0c0;
}

select:focus,
button:focus {
  outline: none;
  border-color: #14899c;
}

.animate-icon {
  fill: #222222;
  height: 10px;
  width: 10px;
}
